import React, { Component } from 'react';
import './App.css';
import './index.css';

class Enrol extends Component {
  render() {
    return (

        <div id="enrol-form" className="modal fade"  role="dialog">
          <div className="modal-dialog">

            {/*-- Modal content--*/}
            <div className="modal-content modal-style">
            <div className="modal-body">
            {/*FORM STARTS HERE*/}
            <iframe title='form' src="https://docs.google.com/forms/d/e/1FAIpQLSeFpErYw0FuKcV6Wdw7SDVqNVR13c8Hxs2czSOQLgw8xj0zWA/viewform?embedded=true" width="640" height="1020" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Enrol;
