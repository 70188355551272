import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Navigation from './navigation';
import Header from './header';
import About from './about';
import Tour from './tour';
import Footer from './fooer';
import Enrol from './enrol-modal';
import * as serviceWorker from './serviceWorker';

//Exporting images
export { default as gal_1 } from './img1.jpg';
export { default as gal_2 } from './img2.jpg';
export { default as gal_3 } from './img3.jpeg';
export { default as gal_4 } from './img4.jpg';
export { default as gal_5 } from './img5.jpeg';
export { default as gal_6 } from './img6.jpg';
export { default as gal_7 } from './img7.jpg';
export { default as icon_1 } from './children-icon.png';
export { default as icon_2 } from './shoes-icon.png';
export { default as icon_3 } from './teacher-icon.png';
export { default as logo } from './logo-cirular.png';
export { default as form1 } from './Students Enrolment Form.pdf';

ReactDOM.render(<Navigation />, document.getElementById('root'));
ReactDOM.render(<Header />, document.getElementById('head'));
ReactDOM.render(<About />, document.getElementById('story'));
ReactDOM.render(<Tour />, document.getElementById('tour'));
ReactDOM.render(<Footer />, document.getElementById('footer'));
ReactDOM.render(<Enrol />, document.getElementById('enrol'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
