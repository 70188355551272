import React, { Component } from 'react';
import './App.css';
import './index.css';
import {icon_1, icon_2, icon_3} from './';

class About extends Component {
  render() {
    return (
        <div className="row story">
          <div className="col-12 tag-1">
            <h4>Learning through <font color="#029df5">play</font> and fun</h4>
            <p>
              Kidz4Africa is an early childhood development center with deep rooted <strong>christian values</strong> and principles.The Kidz4Africa concept was developed in 2018 and we have grown to become a one-stop centre of learning and a great deal of fun; for children between 12 months and 6 years of age.

              Our aim is to provide excellent and affordable <strong>CAPS</strong> and play bassed education. 
            </p>
          </div>
          <div className="col-sm features">
            <img className='icons' src={icon_1} alt='icon' />
            <h5>Safe Environment</h5>
            <p>We are a modern, well equipped facility with a large  child friendly and safe outside play area.
            </p>
          </div>
          <div className="col-sm features">
          <img className='icons' src={icon_2} alt='icon' />
            <h5>Extra Mural Activities</h5>
            <p>
              All extramural activities take place on the school premises. We offer
              <strong> computer classes</strong>, <strong>Wiggle & Jam (Music)</strong>, <strong>Playtime(Sports)</strong>, and <strong>Swimming lessons</strong>.
            </p>
          </div>
          <div className="col-sm features">
          <img className='icons' src={icon_3} alt='icon' />
            <h5>Qualified Staff</h5>
            <p>
            Experienced and passionate staff members help create a fun and advanturous learning environment.
            </p>
          </div>
          <div className='col-12 fees'>
            <p id='fees'></p>
            <hr/>
          </div>
          {/*The collapsable drawers section starts here*/}
          <div className='col-12 values'>
          <div id="accordion">
  <div className="card">
    <div className="card-header" id="headingOne">
      <h5 className="mb-0">
        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        <font color='f5a802' size='4'>Fees 2019 - 2020</font>
        </button>
      </h5>
    </div>

    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body">
      <div className="pay-cont">
            <div className="payment">
              <p>Full Day</p>
              <br/>
              <h3>R1850.00</h3>
              <p>06:00 – 18:00</p>
              <button type="button" className="btn btn-outline-light enrol" data-toggle="modal" data-target="#enrol-form">Enrol now</button>
            </div>
            <div className="payment">
              <p>Half Day</p>
              <br/>
              <h3>R1500.00</h3>
              <p>06:00 – 14:00 </p>
              <button type="button" className="btn btn-outline-light enrol" data-toggle="modal" data-target="#enrol-form">Enrol now</button>
            </div>
            <p><strong>Is there anything additionally that I need to provide?</strong>
            <p><button type="button" className="btn btn-info extras">Download list</button></p>
            </p>
            </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingTwo">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <font color='f50202' size='4'>What we offer</font>
        </button>
      </h5>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div className="card-body info">
        <h3>Security and Safety</h3>
        <p>The school premises are fully enclose, with access controlled gates with private security response seconds away.

        No child will be allowed to leave the school premise with any person/s, without written authorization from the child's parents or legal guardian.</p>
        <h3>Lunches & Snacks</h3>
        <p>Diet plays an important role in the health and development of a child; it is for this reason that we include freshly prepared meals into our daily program. </p>
        <h3>Programs</h3>
        <p>Daily child stimulation and developmental programs with feedback reports.</p>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingThree">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          <font color='02a8f5' size='4'>FAQ's</font>
        </button>
      </h5>
    </div>
    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div className="card-body info">
      <h3>When does the school year start?</h3>
      <p>Kidz4Africa opens January 15th 2020.</p>
      <h3>School Hours?</h3> 
      <p>6:00am - 18:00pm (Monday - Friday) Excluding public holidays and 3 weeks over December/January holidays.</p>
      <h3>Is it safe for my child?</h3>
      <p>Kidz4Africa is situated in a safe area and enforces strict rules to ensure that nobody except authorized persons are allowed to pick up your child from school. In addition, we have effective access control to the school and no unknown or unauthorized persons are allowed on the school premises.</p>
      <h3>My child is ill. What do I do?</h3>
      <p>If your baby’s illness is contagious, by law you are required to keep the child at home in order to prevent the illness from spreading.

      Even if it’s not contagious, we kindly request to please be considerate to your fellow parents and keep your child at home until he or she can return.</p>
      <hr></hr>
      </div>
    </div>
  </div>
</div>
            
          </div>
        </div>

      );
    }
  }
  
  export default About;