import React, { Component } from 'react';
import './App.css';
import './index.css';
import {logo} from './';
import {form1} from './';

class Navigation extends Component {
  render() {
    return (

    <div id="navMenu">
      <div id='logo'>
      <a className="" href="/#">
    <img className='logo' src={logo} alt="main logo"/>
</a>
    </div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <a className="navbar-brand logoplace" href="/#">Kids4Africa</a>

<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
<span className="navbar-toggler-icon"></span>
</button>

<div className="collapse navbar-collapse" id="navbarNavDropdown">
<ul className="navbar-nav ml-auto">
  <li className="nav-item active">
    <a className="nav-link" href="/#">Home<span className="sr-only">(current)</span></a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/#aboutUs" rel="noopener noreferrer">About Us</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/#fees">Fees & Info</a>
  </li>
  <li className="nav-item">
    <a href="#video" className="nav-link">Gallery</a>
  </li>
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" href='/#' id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Registration Forms</a>
    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" href="/#">Forms-MS Word</a>
          <a className="dropdown-item" href={form1} target='blank'>Forms-PDF</a>
        </div>
  </li>
</ul>
</div>
</nav>
  </div>
    );
  }
}

export default Navigation;
