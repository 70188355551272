import React, { Component } from 'react';
import './App.css';
import './index.css';

class Footer extends Component {
  render() {
    return (
        <div className='row footer'>
            <div className='col-sm'>
              <h2>Kidz4Africa</h2>
              <p>We are an early childhood development center with deep rooted christian values and principles.</p> 
              <p><font size="2">Copyright © 2018-2020.
              &nbsp;
                <a href="https://loveypale.pb.online/">
                Design by L Pale</a></font></p>
            </div>
            <div className='col-sm foot_contact'>
            <h3><i className="fas fa-envelope mr-3"></i>
                kidz4africa@hotorange.co.za</h3>
                <p></p>
                <a href='/#' className='social-link'>Home</a><br/>
                <a href='#aboutUs' className='social-link'>About Us</a><br/>
                <a href='#fees' className='social-link'>Fees</a><br/>
                <a href='/#' className='social-link'>Gallery</a><br/>
            </div>
            <div className="col-sm foot_contact">
            <h3><i className="fas fa-mobile-alt mr-3"></i>
            061 077 6644</h3>
                <div id='social-cont'>
                <a href='/#' className='social-link'>
                <i className="fab fa-facebook fa-2x mr-3"></i></a>
                <a href='/#' className='social-link'>
                <i className="fab fa-twitter fa-2x ml-2"></i>
                </a>
                </div>
            </div>
        </div>
      );
    }
  }
  
export default Footer;