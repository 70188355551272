import React, { Component } from 'react';
import './App.css';
import './index.css';

class Header extends Component {
  render() {
    return (
        <div className="row">
          <div className="col-lg head-image">
          <div className='contact'>
            <p>Contact us</p>
            Cnr Edge & Marlbourgh<br/>
            Gardenvale
            Kliprivier<br/>
            <p></p>
            061 077 6644 / 082 888 3199<br/>
            <p></p>
            <strong>Noleen Smit</strong><br/>
            kidz4africa@hotorange.co.za

            
          </div>
            <h1>Pre-School and aftercare<br/> 
18 months to grade R</h1>
            <br/>
            <p>Learning made easy</p>
            
<a href='#video' role="button" className="btn btn-outline-light register" data-target="#video">
Take a tour<i className="fas fa-video ml-2"></i></a>
<button type="button" className="btn btn-light register ml-3" data-toggle="modal" data-target="#enrol-form">Enrol now</button>
<p id='aboutUs'></p>
          </div>
        </div>

      );
    }
  }
  
  export default Header;