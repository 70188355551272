import React, { Component } from 'react';
import './App.css';
import './index.css';
import {gal_1, gal_2, gal_3, gal_4, gal_5, gal_6, gal_7} from './';

class Tour extends Component {
    constructor(props) {
      super(props);
      //Set default states
      this.state = {
          header: '',
          butText: '',
          style: ''
      };
    }
   
    //Handle button actions and update headings 
    updateContent = () => {
        const currentStyle = this.state.style;
        const currentClass = this.state.class;
        const headText = this.state.header;
        const buttonLabel = this.state.butText;
        this.setState({header: !headText,style: !currentStyle, butText: !buttonLabel, class: !currentClass});
    }
  
  render(){
    return (
        <div className='row'>
            <p id='video'></p>
            <div className='col-sm tour-vid'>
                <h4>{this.state.header ? 'Kidz4Africa Gallery' : 'Take a tour of our school'}</h4>
                <button id='tour_btn' className="btn btn-outline-secondary" onClick={this.updateContent}>
                {this.state.butText ? 'Virtual Tour' : 'Photo Gallery'}
                </button>
              <div className={this.state.style ? 'vid-cont-hidden' : 'vid-cont'}> 
              <iframe title="tour" width="100%" height="100%" src="https://www.youtube.com/embed/xiCLKqweXsU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className={this.state.class ? 'pic-gallery' : 'pic-gallery-hidden'}>
              
                <div class="row">
                <div class="col-lg-4">
                    <div class="thumbnail">
                      <a href={gal_1} target="blank">
                    <img class="mySlides" src={gal_1} width="80%" alt='galleryPicture' /></a>
                      </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="thumbnail">
                    <a href={gal_2} target="blank">
                      <img class="mySlides" src={gal_2} width="80%" alt='galleryPicture' /></a>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="thumbnail">
                    <a href={gal_3} target="blank">
                      <img class="mySlides" src={gal_3} width="80%" alt='galleryPicture' /></a>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="thumbnail">
                    <a href={gal_4} target="blank">
                      <img class="mySlides" src={gal_4} width="80%" alt='galleryPicture' /></a>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="thumbnail">
                    <a href={gal_5} target="blank">
                      <img class="mySlides" src={gal_5} width="80%" alt='galleryPicture' /></a>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="thumbnail">
                    <a href={gal_6} target="blank">
                      <img class="mySlides" src={gal_6} width="80%" alt='galleryPicture' /></a>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="thumbnail">
                    <a href={gal_7} target="blank">
                      <img class="mySlides" src={gal_7} width="80%" alt='galleryPicture' /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      );
    }
  }
  
export default Tour;